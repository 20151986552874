import * as React from "react"
import cx from "classnames";
import {signInUrl, signUpUrl} from "../utils/constants";
// import Button from '@material-ui/core/Button';

const style = require("../styles/pageFooter.module.scss");

export default class PageFooter extends React.Component<{}> {
    readonly hello = `Hello`

    public render() {
        return (
            <>
                <div className={cx(style.Footer)}>
                    <div className={'container'}>
                        <div className={cx("row align-items-center justify-content-between")}>
                            <div
                                className={cx("col-sm-12 col-md-auto justify-content-center justify-content-md-start d-flex", style.LeftSection)}>
                                <div className={style.Logo}>Loggy</div>
                            </div>
                            <div
                                className={cx("col-sm-12 col-md-auto justify-content-start justify-content-md-end d-flex")}>
                                <ul className={cx(style.FooterNav, "flex-column flex-md-row align-items-start align-items-md-center")}>
                                    <li><a href={signInUrl}>Logowanie</a></li>
                                    <li><a href={signUpUrl}> Załóż darmowe konto</a></li>
                                    <li><a href="/regulamin">Regulamin</a></li>
                                    <li><a href="/regulamin-oplat">Regulamin opłat</a></li>
                                    <li><a href="/regulamin-aplikacji-mobilnej">Regulamin Aplikacji Mobilnej</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={style.FooterBottom}>
                    <div className={'container'}>
                        <div className={cx("row align-items-center mt-3")}>
                            <div className={cx('col-12', style.Copyrights)}>
                                Loggy {new Date().getFullYear()} &copy;
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
