import * as React from "react"
import Typed from 'typed.js';
import cx from 'classnames';
import Button from "@material-ui/core/Button/Button";
import dashboardImage from '../images/dashboard-img2.png';

import {SignUpButton} from "./SignUpButton";
import {signUpUrl} from "../utils/constants";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Close} from "@material-ui/icons";
import {SetUpCallButton} from "./SetUpCall";

const style = require('../styles/banner.module.scss');


const Banner = ({isHeaderFixed}) => {

    const [isVideoVisible, setVideoVisibility] = React.useState(false);

    const toggleVideo = (visible) => () => {
        setVideoVisibility(visible);
    };

    return (
        <div className={cx(style.Banner, {[style.FixedHeader]: isHeaderFixed})}>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={cx("col-sm-12", "col-lg-6", style.BannerText)}>
                        <h3>
                            Komunikacja w <span>transporcie</span> jeszcze nigdy nie była tak prosta!
                        </h3>
                        <p>
                            Poznaj innowacyjną platformę komunikacyjną dla usług TSL!
                        </p>
                        <div className={cx(style.ButtonsWrapper)}>
                            <SignUpButton className={style.Button} fullWidth={true}/>
                            <Button variant="contained" color="secondary"
                                    onClick={toggleVideo(true)}
                                    className={cx(style.VideoButton, style.Button)}>
                                Zobacz video
                            </Button>
                        </div>
                        <div className={cx(style.ButtonsWrapper, 'mt-3')}>
                            <SetUpCallButton className={style.Button} fullWidth={true}/>
                        </div>
                    </div>
                    <div className={cx("col-sm-12", "col-lg-6", style.DashboardScreen)}>
                        <img src={dashboardImage} alt={"Loggy Dashboard"}/>
                    </div>
                </div>
            </div>
            <Dialog onClose={toggleVideo(false)} fullWidth={true} maxWidth={"lg"} aria-labelledby="loggy-video"
                    open={isVideoVisible}>
                <div className={style.VideoDialog}>
                    <IconButton aria-label="close" size={"small"} disableFocusRipple className={style.CloseVideoButton}
                                onClick={toggleVideo(false)}>
                        <Close/>
                    </IconButton>
                    <div className={style.VideoWrapper}>
                        <iframe src="https://www.youtube.com/embed/Og9aJgH0ThM" frameBorder="0"
                                className={style.Video}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </div>

            </Dialog>
        </div>
    )
}

export default Banner;
