import * as React from "react"
import Button from "@material-ui/core/Button";
import {apiUrl, appUrl, signUpUrl} from "../utils/constants";
import cx from 'classnames';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Slide from "@material-ui/core/Slide/Slide";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Check} from "@material-ui/icons";

const style = require('../styles/setUpCall.module.scss');

interface IProps {
    label?: string;
    fullWidth?: boolean;
    variant?: string;
    className?: string;
    color?: string;
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export const SetUpCallButton = (props: IProps) => {
    const [isModalVisible, showModal] = React.useState(false);
    const [formFields, setFormFields] = React.useState({
        name: '',
        phoneNumber: '',
        email: '',
        companyName: '',
    });
    const [contactRequestSuccess, setContactRequestSuccess] = React.useState(false);
    const [contactRequestError, setContactRequestError] = React.useState(false);
    const [formSubmitted, setFormSubmitted] = React.useState(false);
    const [isLoading, setLoader] = React.useState(false);

    const handleFormSubmit = (event: any) => {
        setFormSubmitted(true);
        event.preventDefault();
        if (!hasErrors()) {
            setContactRequestError(false);
            setLoader(true);
            fetch(`${apiUrl}contactRequest`, {
                body: JSON.stringify(formFields), // body data type must match "Content-Type" header
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'POST' // *GET, POST, PUT, DELETE, etc.
            }).then((response) => {
                if (response.ok) {
                    setContactRequestSuccess(true);
                    setTimeout(() => {
                        handleClose();
                        setContactRequestSuccess(false);
                    }, 1000)
                } else {
                    setContactRequestError(true)
                }
            }).catch(() => {
                setContactRequestError(true)
            }).finally(() => {
                setLoader(false)
            })
        }
    };

    const validateEmail = (e: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(e);
    };

    const handleTextFieldChange = (fieldName: string) => (event: any) => {
        setFormFields({
            ...formFields,
            [fieldName]: event.currentTarget.value
        });
    };

    const handleEmailChange = (event: any) => {
        setFormFields({
            ...formFields,
            email: event.currentTarget.value
        });
    };

    const handleBtnClick = (event: any) => {
        event.preventDefault();
        showModal(true)
    };

    const handleClose = () => {
        showModal(false);
    };

    const validateField = (fieldName) => {
        if (!formSubmitted) {
            return true;
        }
        if (fieldName === 'email') {
            return validateEmail(formFields.email)
        }
        if (fieldName === 'phoneNumber') {
            return (((formFields || {}).phoneNumber || '').match(/\d/g) || '').length >= 9;
        }
        if (formFields[fieldName] && formFields[fieldName].length > 3) {
            return true
        }
        return false;
    }

    const hasErrors = () => Object.keys(formFields).find(key => !validateField(key));

    return (
        <div className={style.SignUpButton} style={{width: '100%'}}>
            <Button variant={props.variant || 'outlined'} color={props.color || "default"} fullWidth={!!props.fullWidth}
                    onClick={handleBtnClick}
                    className={cx('sign-up-btn', props.className)}>
                {props.label || "Umów prezentację online"}
            </Button>
            <div className={style.NewsletterForm}>
                <Dialog open={isModalVisible} onClose={handleClose} aria-labelledby="form-dialog-title"
                        keepMounted={false}
                        TransitionComponent={Transition}>
                    <DialogTitle id="form-dialog-title">Umów się na darmową prezentację online</DialogTitle>
                    <form onSubmit={handleFormSubmit}>
                        <DialogContent>
                            <DialogContentText>
                                Zobacz jak działa platforma <strong>Loggy</strong> podczas indywidualnego spotkania
                                online.
                                Wystarczy 15 minut, aby dowiedzieć się w jaki sposób Loggy znajdzie zastosowanie w
                                Twojej firmie.
                            </DialogContentText>
                            <div className={'row'}>
                                <div className={'col-sm-12 col-lg-6'}>
                                    <TextField
                                        autoFocus={true}
                                        margin="dense"
                                        id="name"
                                        error={!validateField('name')}
                                        label="Imię i nazwisko"
                                        type="text"
                                        onChange={handleTextFieldChange('name')}
                                        value={formFields.name}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        autoFocus={false}
                                        margin="dense"
                                        id="companyName"
                                        error={!validateField('companyName')}
                                        label="Nazwa firmy"
                                        type="text"
                                        onChange={handleTextFieldChange('companyName')}
                                        value={formFields.companyName}
                                        fullWidth={true}
                                    />
                                </div>
                                <div className={'col-12 col-lg-6'}>
                                    <TextField
                                        autoFocus={false}
                                        margin="dense"
                                        id="email"
                                        error={!validateField('email')}
                                        label="Adres e-mail"
                                        type="email"
                                        onChange={handleEmailChange}
                                        value={formFields.email}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        autoFocus={false}
                                        margin="dense"
                                        id="name"
                                        error={!validateField('phoneNumber')}
                                        label="Numer telefonu"
                                        type="phone"
                                        onChange={handleTextFieldChange('phoneNumber')}
                                        value={formFields.phoneNumber}
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            {contactRequestError &&
                            <p className={style.ContactRequestError}>Ups, coś poszło nie tak! Spróbuj ponownie, a jeżeli
                                problem będzie się powtarzał, skontaktuj się z nami.</p>}

                            {!!hasErrors() &&
                            <p className={style.ContactRequestError}>W formularzu wsytąpiły błędy. Popraw pola
                                zaznaczone
                                kolorem czerwonym.</p>}

                            <div className={'row mt-4'}>
                                <div className={'col-6'}>
                                    <Button onClick={handleClose} color="primary">
                                        Anuluj
                                    </Button>
                                </div>
                                <div className={'col-6 d-flex justify-content-end'}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type={'submit'}
                                        className={style.SubmitButton}
                                        disabled={isLoading}
                                    >
                                        <span
                                            className={cx({[style.SuccessButtonText]: contactRequestSuccess})}>Wyślij</span>
                                        {isLoading && <CircularProgress size={24} className={style.Loader}/>}
                                        {contactRequestSuccess && <Check className={style.Loader}/>}
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </form>
                </Dialog>
            </div>
        </div>
    )

}
