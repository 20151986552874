export const featuresList = [
    "Zarządzanie zleceniami",
    "Zarządzanie delegacjami",
    "Komunikaty kierowców",
    "Zarządzanie kierowcami",
    "Dedykowana aplikacja mobilna",
    "Integracja SMS",
    "Wiadomości dla kierowców",
    "Geolokalizacja kierowców",
    "Powiadomienia PUSH",
    "Powiadomienia e-mail dla spedytorów",
    "Szczegółowe statystyki",
    "Dodawanie zdjęć do zlecenia przez kierowcę",
    "Wsparcie techniczne",
    "Własne statusy zleceń",
    "Powiadomienia e-mail dla podmiotów zewnętrznych",
    "Chat grupowy",
];

export const appUrl = process.env.GATSBY_PANEL_URL;
export const apiUrl = process.env.GATSBY_API_URL;

export const signUpUrl = `${appUrl}/#/auth/sign-up`;
export const signInUrl = `${appUrl}/#/auth/sign-in`;
export const getPhoneNumber = () => '+48 797 751 039';
export const plans = [
    {
        description: 'Pakiet dla początkujących',
        driversNumber: 5,
        features: [
            "30 dni historii",
            ...featuresList,
        ],
        isFree: true,
        name: 'Free',
        price: 0,
        storageSpace: '1GB'
    },
    {
        description: 'Pakiet dla małych firm',
        driversNumber: 10,
        features: [
            "1 rok historii",
            ...featuresList,
        ],
        name: 'Basic',
        price: 199,
        storageSpace: '2GB'
    },
    {
        description: 'Pakiet dla średnich firm',
        driversNumber: 50,
        features: [
            "3 lata historii",
            ...featuresList,
        ],
        name: 'Business',
        price: 499,
        storageSpace: '5GB'
    },
    // {
    //     description: 'Pakiet dla dużych firm',
    //     driversNumber: 100,
    //     managersNumber: 5,
    //     name: 'Max',
    //     price: 1000,
    //     storageSpace: '10GB'
    // }
]

export const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.loggy.loggyMobile';
export const appStoreUrl = 'https://apps.apple.com/pl/app/loggy/id1489103260?l=pl';