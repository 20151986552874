import * as React from "react"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {offset: 70})
}
import cx from "classnames";
import Button from '@material-ui/core/Button';
import Close from "@material-ui/icons/Close";
import Menu from "@material-ui/icons/Menu";
import {getPhoneNumber, signInUrl, signUpUrl} from "../utils/constants";
import {SIGN_UP_ENABLED, SignUpButton} from "./SignUpButton";
import style from "../styles/header.module.scss";
import {MutableRefObject, useEffect, useRef, useState} from "react";

interface HeaderProps {
    appUrl?: string;
    isHeaderFixed: boolean;
    parentRef: MutableRefObject<any>;
}

const PageHeader = (props: HeaderProps) => {
    const [mobileMenuVisible, setMobileMenuVisiblity] = useState(false);
    const {isHeaderFixed} = props;
    const toggleMobileMenu = () => {
        setMobileMenuVisiblity(!mobileMenuVisible)
    };
    const loadingRedirect = () => {
        window.location = "https://zaladunek.loggy.pl"
    }

    return (
        <div className={cx({[style.FixedHeaderWrapper]: isHeaderFixed})}>
            <div className={cx(style.Header, {[style.FixedHeader]: isHeaderFixed}, "container-fluid")}>
                <div className={cx(style.HeaderItem, "row", "align-items-center")}>
                    <div
                        className={cx("col-sm-12 col-lg-6  justify-content-between align-items-center justify-content-lg-start", style.LeftSection)}>
                        <a href={'/'} className={style.Logo}>Loggy</a>
                        <ul className={cx(style.Navigation, "d-none", "d-lg-flex")}>
                            <li><a href="/#produkt">Produkt</a></li>
                            <li><a href="/#funkcje">Funkcje</a></li>
                            <li><a href="/#cennik">Cennik</a></li>
                            <li><a href="/#kontakt">Kontakt</a></li>
                            <li>
                                <Button variant="outlined" color="primary" size={"small"} onClick={loadingRedirect}>
                                    Planowanie załadunku
                                </Button>
                            </li>
                        </ul>
                        <Button onClick={toggleMobileMenu} className={'d-block d-lg-none'}><Menu
                            className={cx(style.MenuButton)}/></Button>
                    </div>
                    <div className={cx("col-sm-12 col-lg-6 d-none d-lg-flex justify-content-end")}>
                        <ul className={cx(style.Navigation)}>
                            <li className={style.RightSeparator}>{getPhoneNumber()}</li>
                            {SIGN_UP_ENABLED && <li><a href={signInUrl}>Logowanie</a></li>}
                            <li>
                                <SignUpButton className={style.SignUpBtn}/>
                            </li>
                        </ul>
                    </div>
                </div>
                {mobileMenuVisible &&
                <div className={cx('d-lg-none', style.MobileMenuOutline)} onClick={toggleMobileMenu}/>}
                {mobileMenuVisible && <div className={cx(style.MobileMenu, 'd-lg-none')}>
                    <div>
                        <div className={cx('d-flex justify-content-between')}>
                            <div className={style.Logo}>Loggy</div>
                            <Button><Close className={style.CloseMenu} onClick={toggleMobileMenu}/></Button>
                        </div>
                        <ul className={style.Navigation}>
                            <li><a href="/#produkt">Produkt</a></li>
                            <li><a href="/#cennik">Cennik</a></li>
                            <li><a href="/#kontakt">Kontakt</a></li>
                            <li>
                                <Button variant="outlined" color="primary" size={"small"} onClick={loadingRedirect}>
                                    Planowanie załadunku
                                </Button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className={style.Buttons}>
                            <Button variant={"outlined"} href={signInUrl} color={"primary"}>Logowanie</Button>
                            <SignUpButton/>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
};

export default PageHeader;
