import {graphql} from "gatsby"
import * as React from "react"
import '../styles/global.scss';
import PageHeader from "../components/header";
import Banner from "../components/banner";
import Helmet from "react-helmet";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import PageFooter from "../components/pageFooter";

import {MuiThemeProvider} from "@material-ui/core";
import cx from 'classnames';
import CookieConsent from "react-cookie-consent";
import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";

const style = require('../styles/layout.module.scss');
// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface LayoutProps {
    isHomePage: boolean;
}


export const muiTheme = createMuiTheme({
        typography: {
            fontWeightLight: 100,
            fontWeightRegular: 100,
            useNextVariants: true,
        },
        palette: {
            background: {
                default: '#fff',
                paper: '#2a2d35',
            },
            primary: {
                main: '#7CB342',
                // dark: will be calculated from palette.primary.main,
                contrastText: '#fff'
            },
            secondary: {
                main: '#e51148',
                // dark: will be calculated from palette.secondary.main,

            },
            type: 'dark',
            // error: will use the default color
        },
        overrides: {
            MuiCard: {
                root: {
                    background: 'rgba(68, 70, 79, 0.5)',
                }
            },
            MuiChip: {
                root: {
                    height: '26px',
                    fontSize: '10px'
                }
            },
            MuiTableCell: {
                root: {
                    padding: '4px 12px 4px 12px',
                }
            },
            MuiExpansionPanel: {
                // root: {
                //     background: 'transparent',
                //     boxShadow: 'none',
                //     borderBottom: '1px solid rgba(50, 50, 50, 1);'
                // },
            },
            MuiListItem: {
                root: {
                    paddingLeft: '0',
                }
            },
            MuiExpansionPanelDetails: {
                root: {
                    display: 'block',
                }
            },
            // @ts-ignore
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: 'transparent',
                },
            },
        }
    }
);

const Layout = (props: LayoutProps) => {
    const {isHomePage} = props;
    const [isHeaderFixed, setFixedHeader] = useState(false);
    const headerEl = useRef(null);

    const onScroll = () => {
        if (headerEl && headerEl.current) {
            const headerOffset = headerEl.current.getBoundingClientRect().top;
            if (headerOffset < 0) {
                setFixedHeader(true);
            } else {
                setFixedHeader(false);
            }
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    })


    const topContentEl = useRef(null);
    return (
        <MuiThemeProvider theme={muiTheme}>
            <CookieConsent
                location="top"
                buttonText="Rozumiem"
                contentStyle={{margin: 0, paddingRight: '15px'}}
                cookieName="cookiesAccepted"
                style={{
                    background: "#383B44",
                    fontWeight: 300,
                    alignItems: 'center',
                    fontSize: "13px",
                    position: 'relative',
                    padding: '15px'
                }}
                buttonStyle={{
                    backgroundColor: '#7CB342',
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: 300,
                    margin: 0,
                }}
                expires={150}
            >
                Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia
                danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy.
            </CookieConsent>
            <div className={style.Container}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>LOGGY.pl</title>
                </Helmet>
                <div className={cx(style.TopContent, {[style.HasBg]: isHomePage})} ref={topContentEl}>
                    <div>
                        <header ref={headerEl}>
                            <PageHeader isHeaderFixed={isHeaderFixed}/>
                        </header>
                        {isHomePage && <Banner isHeaderFixed={isHeaderFixed}/>}
                    </div>
                </div>
                {isHomePage
                    ? props.children
                    : <div className={cx("container", style.Content)}>
                        {props.children}
                    </div>}
                <PageFooter/>
            </div>
        </MuiThemeProvider>
    )
};

export default Layout;

