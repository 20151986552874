import * as React from "react"
import Button from "@material-ui/core/Button";
import {apiUrl, appUrl, signUpUrl} from "../utils/constants";
import cx from 'classnames';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Slide from "@material-ui/core/Slide/Slide";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Check} from "@material-ui/icons";

const style = require('../styles/signUpButton.module.scss');

interface IProps {
    label?: string;
    fullWidth?: boolean;
    className?: string;
}

export const SIGN_UP_ENABLED = false;
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export const SignUpButton = (props: IProps) => {
    const [isModalVisible, showModal] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [subscribeSuccess, setSubscribeSuccess] = React.useState(false);
    const [subscribeError, setSubscribeError] = React.useState(false);
    const [emailValid, setEmailValid] = React.useState(true);
    const [isLoading, setLoader] = React.useState(false);

    const handleFormSubmit = (event: any) => {
        event.preventDefault();
        if (email && emailValid) {
            setSubscribeError(false);
            setLoader(true);
            fetch(`${apiUrl}newsletter`, {
                body: JSON.stringify({email}), // body data type must match "Content-Type" header
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                method: 'POST' // *GET, POST, PUT, DELETE, etc.
            }).then(() => {
                setSubscribeSuccess(true);
                setTimeout(() => {
                    handleClose();
                    setSubscribeSuccess(false);
                }, 1000)
            }).catch(() => {
                setSubscribeError(true)
            }).finally(() => {
                setLoader(false)
            })
        } else {
            setEmailValid(false);
        }
    };

    const validateEmail = (e: string) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(e);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.currentTarget.value);
        setEmailValid(validateEmail(event.currentTarget.value));
    }

    const handleBtnClick = (event: any) => {
        event.preventDefault();
        showModal(true)
    }

    const handleClose = () => {
        showModal(false);
    }

    return (
        <div className={style.SignUpButton}>
            <Button variant="contained" color="primary" href={SIGN_UP_ENABLED ? signUpUrl : undefined}
                    fullWidth={!!props.fullWidth}
                    onClick={!SIGN_UP_ENABLED ? handleBtnClick : undefined}
                    className={cx('sign-up-btn', props.className)}>
                {props.label || "ZAŁÓŻ DARMOWE KONTO"}
            </Button>
            <div className={style.NewsletterForm}>
                <Dialog open={isModalVisible} onClose={handleClose} aria-labelledby="form-dialog-title"
                        keepMounted={false}
                        TransitionComponent={Transition}>
                    <DialogTitle id="form-dialog-title">Zostaw e-mail</DialogTitle>
                    <form onSubmit={handleFormSubmit}>
                        <DialogContent>
                            <DialogContentText>
                                Rejestracja jeszcze nie jest dostępna, zostaw swój <strong>adres e-mail</strong>, a
                                zostaniesz poinformowany
                                w momencie gdy uruchomimy rejestrację!
                            </DialogContentText>
                            <TextField
                                autoFocus={true}
                                margin="dense"
                                id="name"
                                error={!emailValid}
                                label="Adres e-mail"
                                type="email"
                                onChange={handleEmailChange}
                                value={email}
                                fullWidth={true}
                            />
                            {subscribeError &&
                            <p className={style.SubscribeError}>Ups, coś poszło nie tak! Spróbuj ponownie, a jeżeli
                                problem będzie się powtarzał, skontaktuj się z nami.</p>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Anuluj
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type={'submit'}
                                className={style.SubmitButton}
                                disabled={isLoading}
                            >
                                <span className={cx({[style.SuccessButtonText]: subscribeSuccess})}>Wyślij</span>
                                {isLoading && <CircularProgress size={24} className={style.Loader}/>}
                                {subscribeSuccess && <Check className={style.Loader}/>}
                            </Button>

                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    )

}
